import { post, deletes, get, downFile } from 'common/request'
export default {
  /**
   * 获取
   */
  // 获取居民档案列表数据
  list: params => get('/residentArchives/list', params),
  // 获取志愿者管理列表数据
  volunteerInfoList: params => get('/volunteerInfo/list', params),
  // 根据id获取居民信息详情
  getResidentArchivesById: id => get(`/residentArchives/getById?id=${id}`),
  // 根据id获取志愿者信息详情
  getVolunteerInfoById: id => get(`/volunteerInfo/getById?id=${id}`),
  // 获取志愿者团队列表数据/获取志愿者可加入团队列表数据 （找团队）
  volunteerGroupList: params => get('/volunteerGroup/groupList', params),
  // 获取志愿者已加入团队列表数据 
  volunteerGroupDetail: params => get(`/volunteerGroupDetail/queryGroups`,params),
  //获取网格树
  getTreeList: args => get('/scGridManagement/getTreeList', args),


  // // 根据id获取走访信息详情
  // getInterviewInfoById: id => get(`/interviewInfo/getById?id=${id}`),
  // 获取省的行政区划
  getRegionInfo: args => get(`/regionInfo/list?level=1`),
  // 获取市级的行政区划
  getProvinceRegionInfo: provinceCode => get(`/regionInfo/list?level=2&&provinceCode=${provinceCode}`),
  // 获取区级的行政区划
  getCityRegionInfo: cityCode => get(`/regionInfo/list?level=3&&cityCode=${cityCode}`),
  // 获取县级的行政区划
  getCountyRegionInfo: countyCode => get(`/regionInfo/list?level=4&&countyCode=${countyCode}`),
  // 获取所有小区
  getGridManagement: args => get('/scGridManagement/queryAll', args),
  // 获取居民信息表格数据
  getPersonTableData: args => get('/residentArchives/listAll', args),
  /**
   * 插入/修改
   */
  // 新增志愿者
  addVolunteerInfo: args => post('/volunteerInfo/add', args),
  // 导出
  exports: args => downFile('/residentArchives/exportXls', args),
  // 根据id删除
  deleteVolunteerInfoById: id => deletes(`/volunteerInfo/deleteById?id=${id}`),
  // 批量删除
  deleteVolunteerInfoByIds: ids => deletes(`/volunteerInfo/ids?ids=${ids}`),
  // 根据id编辑志愿者信息
  updateVolunteerInfo: args => post('/volunteerInfo/update', args),
  // 删除图片
  del: args => get(`/file/del/?id=${args}`),
  // 为志愿者批量添加团队
  addVolunteerGroupDetailList: args => post('/volunteerGroupDetail/addList', args),

  /**
     * 字典
     */
  // form里面的下拉获取数据字典
  dictData: args => get('/dict-data/getByDicKind', args),
}
