<!-- 志愿者新增/编辑弹窗-->
<template>
  <div>
    <el-dialog
      :z-index="10"
      :title="title"
      :visible.sync="visible"
      width="60%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 左边竖线样式写法 -->
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        "
      >
        <div style="display: flex; align-items: center; margin-bottom: 2%">
          <div style="border-left: 4px solid #556bff">&nbsp;</div>
          <div class="search-title">基本信息</div>
        </div>
        <a-button v-if="isEdit" type="primary" @click="choosePeople"
          >选择居民</a-button
        >
      </div>
      <!-- 基本信息 -->
      <ax-form ref="form" :formBuilder="formBuilder1">
        <div slot="file">
          <imagePreview ref="img1" :businessId="businessId"></imagePreview>
        </div>
      </ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmit"
          :loading="saveType"
          style="background-color: #556bff"
          >保 存</el-button
        >
        <el-button @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 选择居民弹窗 -->
    <choose-volunteer
      ref="choosePeople"
      @choose="receiveVolunteerId"
      :selectedPeopleList="selectedPeopleList"
    ></choose-volunteer>
  </div>
</template>
  <script>
const formList1 = [
  {
    label: "姓名",
    type: "",
    model: "residentName",
    options: { allowClear: true, placeholder: "请输入", disabled: true },
    col: { span: 12 },
    rules: [{ required: false, message: "请输入姓名" }],
  },
  {
    label: "身份证号",
    type: "",
    model: "identityCard",
    options: {
      allowClear: true,
      maxLength: 18,
      placeholder: "请输入",
      disabled: true,
    },
    col: { span: 12 },
    rules: [
      {
        required: false,
        message: "请输入",
        pattern:
          /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{4}$/ |
          /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$/,
      },
    ],
  },
  {
    label: "性别",
    type: "select",
    model: "gender",
    options: { disabled: true, placeholder: "请先填写身份证号" },
    col: { span: 12 },
    rules: [{ required: false, message: "请先填写身份证号" }],
  },
  {
    label: "年龄",
    type: "",
    model: "age",
    options: {
      disabled: true,
      placeholder: "请先填写身份证号",
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "出生日期",
    type: "datePicker",
    model: "birthDate",
    options: {
      disabled: true,
      placeholder: "请先填写身份证号",
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  // 志愿者联系电话
  {
    label: "联系电话",
    type: "",
    model: "contactWay",
    options: { placeholder: "请输入", maxLength: 11 },
    col: { span: 12 },
    rules: [
      {
        required: true,
        pattern: /^1[3-9]\d{9}$/,
        message: "请输入正确格式的联系电话",
      },
    ],
  },
  {
    label: "所属网格",
    type: "",
    model: "gridName",
    options: { disabled: true },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "详细地址",
    type: "",
    model: "address",
    options: { placeholder: "请选择详细地址" },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "个人照片",
    type: "",
    model: "file",
    options: {},
    col: { span: 24 },
    rules: [{ required: false, message: "" }],
  },
  // {
  //   label: "所属团队",
  //   type: "select",
  //   model: "groupName",
  //   options: { placeholder: "请选择所属团队", disabled: false },
  //   col: { span: 24 },
  //   rules: [{ required: false }],
  // },
];
import api from "./api";
import imagePreview from "./image-preview/imagePreview";
// import ChoosePeople from "./choosePeople.vue";
import ChooseVolunteer from "./chooseVolunteer.vue";
export default {
  components: {
    imagePreview,
    ChooseVolunteer,
  },
  data() {
    return {
      options: [],
      visible: false,
      isEdit: true,
      businessId: "",
      id: "",
      title: "",
      disabled: false,
      fileIds: [], //图片数组
      saveType: false,
      formBuilder1: this.$common.initGridFormData(
        formList1,
        { layout: "horizontal", labelWidth: 150, disabled: false },
        { col: { span: 8 } }
      ),
      baseInfoValue: {}, //基本信息
      familyInformation: {}, //家庭信息
      BadInformation: {}, //不良行为记录
      selectedPeopleList: [],
      residentName: "", //姓名
      personerIfo: {},
    };
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.businessId = "";
      this.visible = true;
      this.isEdit = true;
      this.saveType = false;
      this.residentName = "";
      this.title = record.title;
      this.selectedPeopleList = record.selectedPeopleList;
      this.$nextTick(() => {
        this.$refs.form.resetFields();
        this.$refs.img1.removeFileList();
      });
      if (record.id) {
        this.isEdit = false;
        this.id = record.id;
        this.businessId = record.residentId;
        this.getPersonInfo(this.businessId);
        this.$nextTick(() => {
          this.$refs.img1.getPicList(record.residentId);
        });
      } else {
        this.id = "";
      }
    },
    // 打开选择居民弹窗
    choosePeople() {
      this.$refs.choosePeople.openModal();
    },
    // 接收子组件传过来的peopleId
    receiveVolunteerId(item) {
      console.log(item);
      if (item.id) {
        this.getPersonInfo(item.id);
        this.residentName = item.residentName;
        this.businessId = item.id;
        this.$nextTick(() => {
          this.$refs.img1.getPicList(item.id);
          this.$refs.form.setFieldsValue({ contactWay: item.phone });
        });
      }
    },

    // 关闭弹窗
    closeModal() {
      this.$refs.form.resetFields();
      this.visible = false;
      this.$emit("refsh");
    },
    // 身份证号计算性别/年龄/出生日期
    calculateIDCardInfo(idCard) {
      let info = {
        gender: "",
        age: "",
        birthDate: "",
      };

      if (!idCard || (idCard.length !== 15 && idCard.length !== 18)) {
        return info;
      }

      let year =
        idCard.length === 15 ? "19" + idCard.substr(6, 2) : idCard.substr(6, 4);
      let month = idCard.substr(idCard.length === 15 ? 8 : 10, 2);
      let day = Number(idCard.substr(idCard.length === 15 ? 10 : 12, 2)) + 1;

      let genderCode =
        idCard.substr(idCard.length - 2, 1) % 2 === 0 ? "女" : "男";
      let birthday = new Date(year, parseInt(month) - 1, day); // 将月份减一
      let age = new Date().getFullYear() - birthday.getFullYear();
      info.gender = genderCode;
      info.age = age;
      info.birthDate = birthday.toISOString().slice(0, 10);
      this.$refs.form.setFieldsValue({ birthDate: info.birthDate });
      this.$refs.form.setFieldsValue({ gender: info.gender });
      this.$refs.form.setFieldsValue({ age: info.age });
      return info;
    },
    // 提交
    onSubmit() {
      if (this.residentName === "") {
        this.$message.warning("请先选择居民");
        return;
      }
      this.$refs.form.form.validateFields(async (err, value) => {
        if (err) return;
        this.saveType = true;
        this.baseInfoValue = value;
        this.baseInfoValue.residentId = this.businessId;
        this.baseInfoValue.auditStatus = "1"; //0:待审核 1：审核通过 2：未通过
        if (this.id) {
          const baseInfoValue = {
            id: this.id,
            residentId: this.businessId,
            contactWay: value.contactWay,
            address: value.address,
            detailedAddress1: value.detailedAddress1,
          };
          const res = await api.updateVolunteerInfo(baseInfoValue);
          if (res.status === 200) {
            this.$message.success("志愿者信息修改成功");
            this.saveType = false;
            this.closeModal();
          } else {
            this.saveType = false;
            this.$message.success("志愿者信息修改失败");
          }
        } else {
          try {
            const res = await api.addVolunteerInfo(this.baseInfoValue);
            if (res.status === 200) {
              this.$message.success("志愿者新增成功");
              this.saveType = false;
              this.closeModal();
            } else {
              this.saveType = false;
              this.$message.success("志愿者新增失败");
            }
          } catch (error) {
            this.saveType = false;
            console.error(error); // 处理错误
          }
        }
      });
    },
    // 根据id获取详情
    async getPersonInfo(id) {
      const res = await api.getResidentArchivesById(id);
      this.residentName = res.data.residentName;
      this.personerIfo = res.data;
      this.calculateIDCardInfo(res.data.identityCard);
      this.$refs.form.setFieldsValue({ ...res.data });
      console.log(res.data, "325");
      if (this.id) {
        this.getVolunteerfo(this.id);
      }
    },
    // 根据id获取志愿者详情信息（联系电话 详细地址 所属团队）
    async getVolunteerfo(id) {
      const res = await api.getVolunteerInfoById(id);
      console.log(res.data, "332");
      this.personerIfo.address = res.data.address || ""; //解决对象覆盖问题
      this.$refs.form.setFieldsValue({ ...res.data, ...this.personerIfo });
    },
  },
};
</script>
  
  <style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}

.dialog-footer {
  display: flex;
  justify-content: end;
}

.search-title {
  // font-size: 0.072rem;
  font-weight: 800;
}
</style>